function getCookie(c_name){
    var c_value = document.cookie;
    var c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start == -1){
        c_start = c_value.indexOf(c_name + '=');
    }
    if (c_start == -1){
        c_value = null;
    }else{
        c_start = c_value.indexOf('=', c_start) + 1;
        var c_end = c_value.indexOf(';', c_start);
        if (c_end == -1){
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start,c_end));
    }
    return c_value;
}
function setCookie(c_name,value,exdays){
    var exdate=new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value=escape(value) + ((exdays==null) ? '' : '; expires='+exdate.toUTCString());
    document.cookie=c_name + '=' + c_value;
}

document.addEventListener('click', function (event) {
    if (!event.target.matches('.okCookies')) return;
    aceptarCookies();
	event.preventDefault();
}, false);

function aceptarCookies() {
    setCookie('cookies','1',365);
    document.getElementById('cookies').style.display='none';
}

function comprobarCookies(){
    var cookies = getCookie('cookies');
    if(cookies == '1' ){
        document.getElementById('cookies').style.display='none';
    }else{
        document.getElementById('cookies').style.display='block';
    }
}

window.onload = function(){
    comprobarCookies();
}